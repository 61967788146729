// CUSTOM KEYSOFT SCRIPTS

(function($) {

  "use strict";
  // FEATURES SECTION TABS

  $('#features-tabs').easytabs({
    animationSpeed: 'normal',
    updateHash: false
  });

  $('.navbar-collapse ul li a').on( 'click' , function() {
      if ($(this).hasClass('has-submenu')){
        return;
      }
      $('.navbar-toggle:visible').click();
  });

  $(function() {
    $('a.page-scroll').on('click', function(event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: $($anchor.attr('href')).offset().top - 64
      }, 1500, 'easeInOutExpo');
      event.preventDefault();
    });
  });

  $('body').scrollspy({
    offset: 64,
    target: '.navbar-fixed-top'
  });

  // ANIMATED MENU
  var cbpAnimatedHeader = (function() {

    var docElem = document.documentElement,
      header = document.querySelector('.navbar-default'),
      didScroll = false,
      changeHeaderOn = 50;

    function init() {
      window.addEventListener('scroll', function(event) {
        if (!didScroll) {
          didScroll = true;
          setTimeout(scrollPage, 100);
        }
      }, false);
      window.addEventListener('load', function(event) {
        if (!didScroll) {
          didScroll = true;
          setTimeout(scrollPage, 100);
        }
      }, false);
    }

    function scrollPage() {
      var sy = scrollY();
      if (sy >= changeHeaderOn) {
        classie.add(header, 'navbar-shrink');
      } else {
        classie.remove(header, 'navbar-shrink');
      }
      didScroll = false;
    }

    function scrollY() {
      return window.pageYOffset || docElem.scrollTop;
    }

    init();

  })();

  $('.openContactWidget').on('click', function(e){
    e.preventDefault();
    e.stopPropagation();
    var container = $('#text-2');
    if (container.hasClass('open')) {
      container.find('.textwidget').slideUp(200);
      container.removeClass('open');
      $('html,body').removeClass('mca-form-open');
    } else {
      container.find('.textwidget').slideDown(200);
      container.addClass('open');
      $('html,body').addClass('mca-form-open');
    }
  });

  $('.has-submenu').on('click', function(e){
      if ($(window).width() < 960) {
          if($(this).next('.submenu').css('opacity') == '0') {
              $(this).next('.submenu').show();
              $(this).next('.submenu').css({"visibility": "visible", "opacity": "1", "position": "relative", "transform": "translateY(20px)"});
          } else {
              $(this).next('.submenu').hide();
              $(this).next('.submenu').css({"visibility": "hidden", "opacity": "0", "position": "absolute", "transform": "translateY(20px)"});
          }
      }
  });

})(jQuery);
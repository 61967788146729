/**
 * Created by Søren on 31-05-2016.
 */
var wsd = {
    contactWidget: function (selector) {
        selector.html(wsd.content);
    },
    content: '<aside id="text-2" class="widget widget_text visible">' +
    '<h3 class="widget-title">Need help?</h3>' +
    '<div class="success-msg" style="display: none">Thank for contacting us. We will be contacting you as soon as possible. </div>' +
    '<div id="cc-form" class="textwidget" style="display: none;">Have a question? contact us!' +
    '<form class="epm-sign-up-form" name="epm-sign-up-form">' +
    '<div class="error error-name" style="display:none;">Please fill in your name</div>' +
    '<div class="error error-email" style="display:none;">Please fill in your email</div>' +
    '<div class="error error-msg" style="display:none;">Please fill in the message</div>' +
    '<div class="epm-form-field">' +
    '<label for="epm-first-name">Name</label>' +
    '<input type="text" placeholder="Name" name="name" tabindex="7" class="name first-name" id="form-name">' +
    '</div>' +
    '<div class="epm-form-field">' +
    '<label for="epm-email">Email Address</label>' +
    '<input type="email" placeholder="Email Address" name="email" tabindex="8" class="email" id="form-email" value="">' +
    '</div>' +
    '<div class="epm-form-field">' +
    '<label for="epm-message">Message</label>' +
    '<textarea name="message" tabindex="9" id="form-message"></textarea>' +
    '</div>' +
    '<input type="submit" name="submit" value="Send" data-wait-text="Please wait..." tabindex="10" class="button btn epm-sign-up-button submit">' +
    '</form>' +
    '</div>' +
    '</aside>',
    credentials: {
        name: '',
        email: ''
    },
    autoShowContactWidgetTimeout: null
};

function autoShowContactWidget(time) {
    var hasShown = Cookies.get('auto_shown_contactWidget');

    if (hasShown === undefined) {
        clearTimeout(wsd.autoShowContactWidgetTimeout);
        wsd.autoShowContactWidgetTimeout = setTimeout(function () {
            showContactWidget($('#text-2'));
        }, time);
    }
}

$(window).load(function () {

    $('#form-name').val(wsd.credentials.name);
    $('#form-email').val(wsd.credentials.email);

    $('.submit').click(function () {
        var form = $(this);
        var name = $('#form-name').val();
        var email = $('#form-email').val();
        var message = $('#form-message').val();
        $('.error').hide();

        if (name.replace(/^\s+/g, '').length <= 0) {
            $('.error-name').show();
            return false;
        }

        if (email.replace(/^\s+/g, '').length <= 0) {
            $('.error-email').show();
            return false;
        }

        if (message.replace(/^\s+/g, '').length <= 0) {
            $('.error-msg').show();
            return false;
        }

        //change submit button text
        var submit_wait_text = $(this).data('wait-text');
        var submit_orig_text = $(this).val();
        $(this).val(submit_wait_text);
        Cookies.set('auto_shown_contactWidget', true, {expires: 365});

        $.ajax({
            type: 'POST',
            context: this,
            url: 'https://app.backupbird.com/mail/contact-widget',
            data: {
                name: name,
                email: email,
                message: message
            },
            success: function (data, textStatus, XMLHttpRequest) {
                $("#cc-form").hide();
                $(".success-msg").show();
                $('#form-name').val("");
                $('#form-email').val("");
                $('#form-message').val("");
                $(form).val(submit_orig_text); // restore submit button
            },
            error: function (XMLHttpRequest, textStatus, errorThrown) {
                alert('Something went wrong!');
                $(form).val(submit_orig_text); // restore submit button
            }
        });
        return false;
    });

    var container = $('#text-2');

    container.append('<a href="#" class="toggle-mc-form" id="close-signup-form"><i class="fa fa-times"></i></a>');
    container.append('<a href="#" class="toggle-mc-form" id="mobile-mc-text"><i class="fa fa-envelope"></i></a>');

    container.addClass('visible');

    $('html').on('click', function (e) {
        if ($(e.target).hasClass('toggle-contact-widget'))
            return;
        hideContactWidget(container);
    });

    container.on('click', function (e) {
        e.stopPropagation();
    });

    $(document).on('click', '.toggle-contact-widget', function (e) {
        toggleContactWidget(e);
    });
    container.on('click', '.widget-title, .toggle-mc-form', function (e) {
        toggleContactWidget(e);
    });
});

function toggleContactWidget(e) {
    e.preventDefault();
    e.stopPropagation();
    var container = $('#text-2');
    if(container.hasClass("open"))
        hideContactWidget(container);
    else
        showContactWidget(container);
    Cookies.set('auto_shown_contactWidget', true, {expires: 365});
}
function showContactWidget(container) {
    $(".success-msg").hide();
    container.find('.textwidget').slideDown(200);
    container.addClass('open');
    $('html,body').addClass('mca-form-open');
}
function hideContactWidget(container) {
    container.find('.textwidget, .success-msg').slideUp(200);
    container.removeClass('open');
    $('html,body').removeClass('mca-form-open');
}